export const environment = {
  production: false,
  basePath: 'https://harrisdms-dev-api.rainbow.health/api',
  tenant: 'rcdms003',
  supportedCountry: ['IN', 'US'],
  idleTimeEnable: true,
  signInVersion: '1.0.25',
  samlLoginUrl: 'https://harrisdms-dev-api.rainbow.health/api/rcdms003/saml2/ssologin/web',
  firebase: {
    apiKey: 'AIzaSyBqFvpLk8C5xmp92JkFOW30K08AvaoAEbE',
    authDomain: 'landmark-housecalls-dev.firebaseapp.com',
    projectId: 'landmark-housecalls-dev',
    storageBucket: 'landmark-housecalls-dev.appspot.com',
    messagingSenderId: '684605695872',
    appId: '1:684605695872:web:4146f789f92fe11e7f4365',
    measurementId: 'G-SZH7K91Y70'
  }
};
