import { NavigationEnd, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AppSettings } from './../../app.settings';
import { UiService } from '../../services/ui.service';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';
import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { NotificationsService } from './../../services/notifications.service';
import { EditProfileComponent } from './../edit-profile/edit-profile.component';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { ContactFormService } from 'app/modules/contact-form/services/contact-form.service';
import { CreateComponentComponent } from 'app/modules/contact-form/components/lookup/create-component/create-component.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessagingService } from '../../services/messaging.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService]
})

export class HeaderComponent implements OnInit, OnDestroy {
  ref: DynamicDialogRef | undefined;
  @ViewChild(EditProfileComponent) editPro: EditProfileComponent;
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  notifications: any = [];
  notificationCount: any;
  unReadNotificatonsCount = 0;
  unReadNotificatonsGreaterCount = '99+';
  limit = 8;
  offset = 0;
  showMoreOff = true;
  hasShowNotificationBar = false;
  notificationLabel;
  noNotificationFoundLabel;
  secondsCounter: any;
  logoutSession: any;
  headerConfirmDialog = false;
  headerMessage;
  showDualBookingBtn: boolean;
  label_pending_approval_lbl: any;
  pendingCount: string;
  disablePendingApproval: boolean;
  adminUser = false;
  subscriptionToPendingApproval: Subscription;
  showDispatchBtn = false;
  rabbitMQEnabled = false;
  token: any;
  showContactBtn = false;
  shiftDetailsPopup = false;
  shiftManagerpopupHeading = '';
  shiftManagerNotes = '';
  shiftManagerNotesData: any;
  shiftManagerEllipsisText: any;
  managerNotesViewAccess = false;
  managerNotesAddEditAccess = false;
  managerNotesDeleteAccess = false;
  labelYes: any;
  labelNo: any;
  shiftManagerValidation = false;
  shiftDetailsTooltipPopup = false;
  timeout: any;
  clientDisplayName: any;
  storedFirstName: string;
  storedLastName: string;
  dialogRef: DynamicDialogRef;
  constructor(
    private uiService: UiService,
    private userCommandService: UserCommonService,
    private contactFormService: ContactFormService,
    private router: Router,
    private commonService: CommonBindingDataService,
    private storageService: StorageService,
    private notificationsService: NotificationsService,
    private confirmationService: ConfirmationService,
    private commonBindingDataService: CommonBindingDataService,
    private dialogService: DialogService,
    private messageingService: MessagingService
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('/dashboard') || event.url.includes('/patients') ||
          event.url.includes('/hc-providers') || event.url.includes('/appointments') ||
          event.url.includes('/provider-availability')) {
          this.showDualBookingBtn = true;
        } else {
          this.showDualBookingBtn = false;
        }
      });
    setTimeout(() => {
      this.clientDisplayName = this.commonBindingDataService.getTenantConfigValue(AppSettings.CLIENT_DISPLAY_NAME_WEB);
    }, 1500);
    setTimeout(() => {
      //  this.rabbitMQEnabled = this.storageService.getItem(AppSettings.RABBITMQ_ENABLED);
      const dispatchAccess: any = this.storageService.getItem('headerAccessPermissionDispatch');
      this.showDispatchBtn = dispatchAccess;
      const contactFormAccess: any = this.storageService.getItem('headerAccessPermissionContactForm');
      this.showContactBtn = contactFormAccess;
    }, 1500);
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  handleUserActivity() {
    // Reset the timer on user activity
    const isLogout = this.storageService.getItemLocalStorage('isLogout');
    if (isLogout === false) {
      this.resetTimer();
    } else {
      this.headerConfirmDialog = false;
      this.storageService.logoutPopup.next(true);

    }
  }

  resetTimer() {
    // Clear the existing timeout
    clearTimeout(this.timeout);
    // Set a new timeout for n minutes
    const idleTimeOutDurationInMin = +(this.storageService.getItemLocalStorage('idleTime'));
    this.storageService.setLocalStorage('idleSessionStartTime', Date.now());
    this.timeout = setTimeout(() => {
      // Show the popup after n minutes of inactivity
      const pastDate = +(this.storageService.getItemLocalStorage('idleSessionStartTime'));
      if (Date.now() - pastDate >= idleTimeOutDurationInMin * 60 * 1000) {
        this.confirmYouThere();
      }
    }, idleTimeOutDurationInMin * 60 * 1000);
  }

  ngOnInit() {
    this.storageService.setLocalStorage('isLogout', false);
    this.storageService.setLocalStorage('showIdlePopup', Date.now());
    this.subscriptionToPendingApproval = this.commonService.getMessage().subscribe(msg => {
      if (msg === 'update pending count') {
        this.getNotificationUnReadcountCount();
      }
    });
    this.token = sessionStorage.getItem('logoutToken');
    this.getUserMetaData();
    // this.setSessionInterval();
    this.notificationLabel = this.commonService.getLabel('label_notifications');
    this.label_pending_approval_lbl = this.commonService.getLabel('label_pending_approval_lbl');
    this.noNotificationFoundLabel = this.commonService.getLabel('label_no_notification_found');

    this.items = [
      {
        label: 'Edit Profile', icon: 'fa fa-user-circle-o', command: (event) => {
          this.editProfile();
        }
      },
      {
        label: 'Change Password', icon: 'fa  fa-key', command: (event) => {
          this.changePassword();
        }
      },
      {
        label: 'Support', icon: 'fa fa-cog', command: (event) => {
          this.toSupport();
        }
      },
      {
        label: 'Logout', icon: 'fa fa-sign-out', command: (event) => {
          this.doSignOut();

        }
      }
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (userObj.userDetails !== undefined && userObj.userDetails !== null
        && userObj.userDetails.profileImage !== null && userObj.userDetails.profileImage !== '') {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      } else {
        this.profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      }
    }

    this.subscription = this.uiService.profileUpdatedEvent
      .subscribe((state: string) => {
        this.updateProfileContent();
      });

    this.notificationProcessInitialization();
    if (this.commonService.hasAccessPresentHeader('GET_MANAGER_NOTES_DETAIL')) {
      this.managerNotesViewAccess = true;
      this.getShiftManagerNotes();
    }
    if (this.commonService.hasAccessPresentHeader('ADD_UPDATE_MANAGER_NOTES')) {
      this.managerNotesAddEditAccess = true;
    }
    if (this.commonService.hasAccessPresentHeader('DELETE_MANAGER_NOTES')) {
      this.managerNotesDeleteAccess = true;
    }
    this.labelYes = this.commonService.getLabel('label_yes');
    this.labelNo = this.commonService.getLabel('label_no_without_dot');
  }

  getUserMetaData() {
    const interval = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
    if (interval && interval > 0) {
      this.userCommandService.getUserMasterMetaData();
    }
  }

  notificationProcessInitialization() {
    this.getNotificationUnReadcountCount();
    this.notificationsService.startFetchingNotifications(() => {
      this.getNotificationUnReadcountCount();
    });
  }

  getNotificationUnReadcountCount() {
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj.roles[0].roleName === 'System Admin' || userObj.roles[0].roleName === 'Admin') {
      this.adminUser = true;
    }
    this.notificationsService.getNotificationCount(userObj.id).subscribe(data => {
      this.unReadNotificatonsCount = data.unReadcount;
      this.disablePendingApproval = true;
      const count = data.pendingApprovalcount;
      this.pendingCount = count + ' ' + this.label_pending_approval_lbl;
      if (count === 0) {
        this.disablePendingApproval = false;
      }
    });
  }

  getNotificationListAndToggle(event) {
    this.unReadNotificatonsCount = 0;
    this.limit = 9;
    this.offset = 0;
    this.notifications = [];
    this.getNotificationList();
    this.hasShowNotificationBar = true;
  }

  getNotificationList() {
    this.notificationsService.getDetails(this.limit, this.offset).subscribe(notification => {
      if (notification.notificationList.length > 0) {
        // this.notificationCount = notification.count;
        this.offset = this.offset + notification.notificationList.length;
        this.notifications = [...this.notifications, ...notification.notificationList];

        this.limitReached(notification);
      } else {
        this.uiService.showMessage(this.noNotificationFoundLabel, AppSettings.MESSAGE_TYPES.ERROR);
      }
    });
  }

  limitReached(notification) {
    const currentcount = this.limit + this.notifications.length;
    // if (currentcount >= notification.notificationList.length && currentcount <= this.limit) {
    if (notification.notificationList.length === 0 || notification.notificationList.length < 9) {
      this.showMoreOff = true;
    } else {
      this.showMoreOff = false;
    }
  }

  loadMoreNotification() {
    this.getNotificationList();
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.logout();
  }

  logout() {
    //this.userCommandService.signOutSSO(sessionStorage.getItem('logoutToken')).subscribe(results => {
    this.userCommandService.signOut().subscribe(results => {
      this.storageService.removeAllCookies();
      this.storageService.removeAll();
      this.notificationsService.stopFetchingNotifications();
      this.notificationsService.canFetchNotification = false;
      this.headerConfirmDialog = false;
      clearTimeout(this.logoutSession);
      clearTimeout(this.timeout);
      this.router.navigate(['/signin-sso']);
      // setTimeout(() => {
      //   // this.storageService.setLocalStorage('isLogout', true);
      //   console.log("hi");
      // }, 1000);
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  updateProfileContent() {
    this.userCommandService.getUserProfile().subscribe(results => {
      this.storageService.setItem(AppSettings.USER_DETAILS, results);
      AppSettings.USER = results;
      this.userFullName = results.firstName + ' ' + results.lastName;
      if (results.userDetails !== undefined && results.userDetails !== null && results.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + results.userDetails.profileImage;
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  changePassword() {
    this.changePasswordPopup = true;
  }

  editProfile() {
    this.editProfilePopup = true;
    this.editPro.setData();
  }

  toSupport() {
    const host = window.location.origin;
    const url = `${host}/#/support`;
    window.open(url, '_blank');
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  // Existing logic for the idle timeout which is static after defined time. It doesn't take into accountability of idle screen. 
  // setSessionInterval() {
  //   const sessionInterval = timer(10000, 1000);
  //   this.secondsCounter = sessionInterval.subscribe(n => {
  //     const idleSessionTime = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
  //     if (idleSessionTime) {
  //       if (idleSessionTime < new Date().getTime()) {
  //         this.confirmYouThere();
  //         this.secondsCounter.unsubscribe();
  //       } else {
  //         clearTimeout(this.logoutSession);
  //       }
  //     }
  //   });
  // }

  confirmYouThere() {
    this.sessionTimeout();
    this.headerConfirmDialog = true;
    this.headerMessage = this.commonService.getLabel('lbl_confirm_idle_session');
  }

  // Existing logic for the idle timeout
  // sessionTimeout() {
  //   this.logoutSession = setTimeout(() => {
  //     const idleSessionTime = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
  //     if (idleSessionTime < new Date().getTime()) {
  //       this.logout();
  //     } else {
  //       clearTimeout(this.logoutSession);
  //     }
  //   }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  // }

  sessionTimeout() {
    this.logoutSession = setTimeout(() => {
      this.logout();
    }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  }

  refreshSession() {
    const idleLogOutTime = this.storageService.getItem('idleTime');
    const idleTime = '' + moment().add((idleLogOutTime), 'm').valueOf();
    this.storageService.setLocalStorage(AppSettings.INTERVAL, idleTime);
    clearTimeout(this.logoutSession);
    this.userCommandService.sesionRefresh().subscribe(() => { });
    // this.setSessionInterval();
    this.headerConfirmDialog = false;
  }

  cancel() {
    this.headerConfirmDialog = false;
  }

  ngOnDestroy() {
    this.notificationsService.stopFetchingNotifications();
    this.subscription.unsubscribe();
    // this.secondsCounter.unsubscribe();
    clearTimeout(this.logoutSession);
    clearTimeout(this.timeout);
    this.storageService.removeAllCookies();
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  gotoBookDualAppt() {
    this.router.navigate(['/appointments/book-dual-appointment']);
  }

  gotoAppointments() {
    if (this.router.url === '/appointments') {
      this.commonService.updatePendingFilter('set pending filter');
    } else {
      localStorage.setItem('isPendingApproval', 'true');
    }
    this.router.navigate(['/appointments']);
  }

  gotoAddDispatchRequest() {
    this.router.navigate(['/dispatch-request/add-dispatch-request-info']);
  }
  gotoAddContactForm() {
    this.contactFormService.isContactFormPage = true;
    this.contactFormService.getContactFormId().subscribe(results => {
      this.storageService.setItem(AppSettings.TEMP_CONTACTFORM_ID, results.general[0].id);
      this.router.navigate(['/contact-form/add-contact-form']);
    }, (error) => {
    });
  }

  showShiftDetailsPopup(popup: string) {
    this.shiftManagerpopupHeading = popup === 'edit' ? 'label_shift_manager_heading' : 'label_shift_manager_heading_add';
    this.shiftDetailsPopup = true;
  }

  hideShiftDetailsPopup() {
    this.shiftDetailsPopup = false;
  }

  getShiftManagerNotes() {
    this.notificationsService.getShiftManagerDetails('MANAGER_NOTES').subscribe(results => {
      this.shiftManagerNotesData = results.notes;
      this.shiftManagerNotes = this.shiftManagerNotesData;
      if (this.shiftManagerNotesData) {
        const shiftManagerStr = this.shiftManagerNotesData.replace(/<\/.*?>/g, ' ');
        const strValue = shiftManagerStr.replace(/<(?:.|\n)*?>/gm, '');
        const n = 45;
        this.shiftManagerEllipsisText = strValue.substring(0, n);
      }
    }, (error) => {
    });
  }

  getFormData() {
    const requestModel: any = {
      'notes': this.shiftManagerNotes
    };
    return requestModel;
  }

  saveShiftManagerNotes() {
    const dataModel = this.getFormData();
    this.notificationsService.setShiftManagerDetails(dataModel).subscribe(results => {
      this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.shiftDetailsPopup = false;
      this.getShiftManagerNotes();
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  deleteShiftManager() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this shift details?',
      accept: () => {
        this.notificationsService.deleteShiftManagerDetails('MANAGER_NOTES').subscribe(results => {
          this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
          this.shiftManagerNotesData = '';
          this.shiftManagerNotes = '';
        }, (error) => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
        });
      },
      key: 'shiftManagerConfirmation'
    });
  }

  shiftDetailsValidation(event) {
    if (event.htmlValue) {
      const emptyText = event.htmlValue.replace(/(<([^>]+)>)/ig, '');
      const spaceCheck = emptyText.startsWith(' ');
      spaceCheck ? this.shiftManagerValidation = true : this.shiftManagerValidation = false;
    }
  }

  tooltipShow() {
    this.shiftDetailsTooltipPopup = true;
  }

  tooltipHide() {
    this.shiftDetailsTooltipPopup = false;
  }

  showDialog() {
    this.dialogRef = this.dialogService.open(CreateComponentComponent, {
     // header: 'Look Up Client Data',
      width: '75%',
      contentStyle: { "max-height": "1200px", "overflow": "auto", "height": "auto" },
      data: { firstName: '', lastName: '' },
      closeOnEscape: false,
      dismissableMask: false
    });
    /* ref.onClose.subscribe((data: any) => {
      
     }); */
  }
}


