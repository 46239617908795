<div class="signin-box-container ui-g">
    <div class="signin-box-inner-container">
      <div class="signin-box ">
        <div class="sign-comatiner">
  
          <p-panel [showHeader]="false" [style]="{width: '440px', margin: 'auto'}">
            <div class="form-field ui-g-12 ui-g-nopad">
              <div class="form-field ui-g-12 ui-g-nopad text-center">
                <img class="company-logo-background" src='/assets/images/temp/rainbowcare_logo.png'>
              </div>
              <div class="form-field ui-g-12 switch-btn" translate>label_log_in</div>
              <div class="form-field ui-g-12"></div>
            </div>
            <app-login (isSignInEmitter)="doSignIn($event)" (isForgotPasswordEmitter)="forgotPassword()"></app-login>
             <a [href]="samlLoginUrl"> Login with SSO</a>
            <div class="remove-float"></div>
  
            <div class="txtlogin">
              <div class="version-number">Version {{ signInVersion }}</div>
                  <!-- TODO this was commented on Solari No 21 -->
              <div class="powered_by"><span class="poweredbytxt">Powered By:</span> <a href="https://rainbow.health/" target="_blank" translate> label_rainbow_llc</a></div>
            </div>  
          </p-panel>
  
        </div>
      </div>
    </div>
  </div>
  
  <p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-popup" position="center center"
    responsive="true" [(visible)]="forgotPopup" [closable]="false" [style]="{width: '500px'}">
    <app-forgot-password (popSave)="onPopSave($event)" (popClose)="onPopClose($event)"></app-forgot-password>
  </p-dialog>
  
  <p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-popup" position="center center"
    responsive="true" [(visible)]="otpPopup" [closable]="false" [style]="{width: '500px'}">
    <app-otp-verifiy [email]="otpEmail" [phone]="otpPhone" (otpPopClose)="onOtpPopClose($event)"
      (otpPopSave)="onOtpPopSave($event)"></app-otp-verifiy>
  </p-dialog>
  
  <p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-popup" position="center center"
    responsive="true" [(visible)]="setPasswordPopup" [closable]="false" [style]="{width: '500px'}">
    <app-set-password [otpId]="otpId" (isSetPasswordPopClose)="onSetPasswordPopClose($event)"></app-set-password>
  </p-dialog>
  