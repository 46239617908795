import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { ManageOpenShiftsService } from '../../services/manage-open-shifts.service';
import * as moment from 'moment';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-my-open-shifts',
  templateUrl: './my-open-shifts.component.html',
  styleUrls: ['./my-open-shifts.component.scss']
})
export class MyOpenShiftsComponent implements OnInit {
  subject: Subject<any>;
  openShiftStatusColors = AppSettings.OPEN_SHIFT_STATUS;
  date = new Date();
  hideFilterSection = false;
  searchedTeam = '';
  statusList = [];
  shiftDetails = [];
  openShiftStatusList = [];
  selectedStatus = '';
  hasShowOpenShiftPopUp = false;
  childView: any;
  // hasShowPublishShiftPopUp: boolean = false;
  currentTeamDetails: any;
  maxDate = new Date();
  userId = '';
  isSubmitted = false;

  constructor(public manageOpenShiftsService: ManageOpenShiftsService,
    private storageService: StorageService,
    private uiService: UiService,
    private commonService: CommonBindingDataService, ) {
    this.subject = new Subject();
  }

  ngOnInit(): void {
    // Disable dates after 2 months
    this.maxDate = new Date(this.maxDate.getTime() + (60 * 24 * 3600000));
    const userInfo: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    this.userId = userInfo?.id;
    this.manageOpenShiftsService.getValue().subscribe(value => {
      this.getOpenShiftDetails(this.date);
    });
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.changeFilter();
    });
    // this.getOpenShiftDetails(this.date);
    const tagsData = this.storageService.getItem(AppSettings.TAG_DATA);

    if (tagsData == null) {
      const localTagData = localStorage.getItem('localTagdata');
      this.setDropdownValues(JSON.parse(localTagData));
    } else {
      this.setDropdownValues(tagsData);
      localStorage.removeItem('localTagdata');
    }
  }

  setDropdownValues(tagsData) {
    tagsData.my_open_shifts.forEach(element => {
      this.openShiftStatusList.push({ label: element.displayValue, value: element.tagValue });
    });
    this.statusList.push({ label: this.commonService.getLabel('All'), value: '' });
    this.statusList = [...this.statusList, ...this.openShiftStatusList];
  }

  searchTeam() {
    this.subject.next();
  }

  hideFilter(event) {
    this.hideFilterSection = event === true ? true : false;
  }

  changeFilter() {
    let filter = '';
    if (this.selectedStatus !== undefined && this.selectedStatus !== null && this.selectedStatus !== '') {
      filter = `${filter}&shiftStatus=${this.selectedStatus}`;
    }
    if (this.searchedTeam !== undefined && this.searchedTeam !== null && this.searchedTeam !== '') {
      filter = `${filter}&searchText=${this.searchedTeam}`;
    }
    this.getOpenShiftDetails(this.date, filter);
  }

  // openShift() {
  //   this.hasShowPublishShiftPopUp = true;
  // }

  // onClickOfPopupButton(event) {
  //   if (event !== null) {
  //     this.uiService.showMessage(event.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
  //   }
  //   this.hasShowPublishShiftPopUp = false;
  // }

  openTeamPopup(openShift) {
    this.currentTeamDetails = openShift;
    this.hasShowOpenShiftPopUp = true;
  }

  getOpenShiftDetails(date: any, filter?: any) {
    let filterData = moment(date).format('YYYY/MM/DD');
    if (filter) {
      filterData = `${filterData}${filter}`;
    }
    this.manageOpenShiftsService.getMyOpenShiftDetails(filterData, this.userId).subscribe((shiftDetails) => {
      this.shiftDetails = shiftDetails.data;
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  onResetFilter() {
    this.date = new Date();
    this.getOpenShiftDetails(this.date);
    this.selectedStatus = '';
    this.searchedTeam = '';
  }

  applyLeave(isLeaveApply: boolean) {
    this.isSubmitted = true;
    const applicationStatus = isLeaveApply ? 8020 : 8070;
    const userDetails = {
      openShiftId: this.currentTeamDetails.id,
      applicationStatus
    };
    this.manageOpenShiftsService.applyDenyLeave(this.currentTeamDetails.userId, userDetails).subscribe((res) => {
      this.hasShowOpenShiftPopUp = false;
      this.isSubmitted = false;
      this.getOpenShiftDetails(this.date);
      this.uiService.showMessage(res.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
    }, (error) => {
      this.isSubmitted = false;
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

}
