import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from './../components/loader/loader.service';
import * as moment from 'moment';
import 'moment-timezone';
import { CommonBindingDataService } from './common-binding-data.service';
import { Token } from '@angular/compiler';
import { MessageService } from 'primeng';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
    'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
    'Accept': AppSettings.HEADER_CONTENT_TYPE
  })
};
@Injectable()
export class RestApiService {

  constructor(private httpClient: HttpClient, private zone: NgZone,
    private router: Router, private storageService: StorageService,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {
  }

  private prependApiUrl(url: string): string {
    return AppSettings.BASE_URL + '/' + AppSettings.TENANT + url;
  }

  get(identifier: string, url: string, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithoutBody('get', identifier, url));
  }

  getWithBody(identifier: string, url: string, body: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('get', identifier, url, body));
  }

  post(identifier: string, url: string, body: any, loader?: string, isToken?: boolean): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('post', identifier, url, body, isToken));
  }

  delete(identifier: string, url: string, loader?: string): Observable<{}> {
    return this.handleHttpSuccess(this.callerWithoutBody('delete', identifier, url));
  }

  put(identifier: string, url: string, body?: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('put', identifier, url, body));
  }

  patch(identifier: string, url: string, body: any, loader?: string, options = {
    headers: this.getHttpClientHeaders()
  }): Observable<{}> {
    this.showLoader(loader);
    return this.httpClient.patch(this.prependApiUrl(url), body, options);
  }

  head(identifier: string, url: string, loader?: string, options = {
    headers: this.getHttpClientHeaders()
  }): Observable<{}> {
    this.showLoader(loader);
    return this.httpClient.head(this.prependApiUrl(url), options);
  }

  options(identifier: string, url: string, loader?: string, options = {
    headers: this.getHttpClientHeaders()
  }): Observable<{}> {
    this.showLoader(loader);
    return this.httpClient.options(this.prependApiUrl(url), options);
  }

  excel(identifier: string, url: string, fileName: string, loader?: string) {
    if (identifier !== 'sign-out' && identifier !== 'meta-data' && identifier !== 'public') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    this.showLoader(loader);
    return this.httpClient.get(this.prependApiUrl(url), {
      headers: this.getHttpClientHeaders(), withCredentials: true,
      responseType: 'blob', observe: 'response'
    }).subscribe(data => {
      let name = data.headers.get('content-disposition');
      if (name === undefined || name === null || name === '') {
        name = fileName;
      } else {
        name = name.substring(name.indexOf('='));
        name = name.replace('=', '');
      }
      this.downloadFile(data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name);
    }),
      error => this.handleError(error);
  }

  pdf(identifier: string, url: string, fileName: string, loader?: string,
    options = {
      headers: this.getHttpClientHeaders(), responseType: 'blob'
    }) {
    if (identifier !== 'sign-out' && identifier !== 'meta-data' && identifier !== 'public') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    this.showLoader(loader);
    return this.httpClient.get(this.prependApiUrl(url), {
      headers: this.getHttpClientHeaders(),
      withCredentials: true, responseType: 'blob', observe: 'response'
    }).subscribe(data => {
      let name = data.headers.get('content-disposition');
      if (name === undefined || name === null || name === '') {
        name = fileName;
      } else {
        name = name.substring(name.indexOf('='));
        name = name.replace('=', '');
      }
      this.downloadFile(data.body, 'application/pdf', name);
    }),
      error => this.handleError(error);
  }

  image(identifier: string, url: string, fileName: string, loader?: string) {
    if (identifier !== 'sign-out' && identifier !== 'meta-data' && identifier !== 'public') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    this.showLoader(loader);
    return this.httpClient.get(url, {
      headers: this.getHttpClientHeaders(), withCredentials: true,
      responseType: 'blob'
    }).subscribe(data =>
      this.downloadFile(data, this.getContentType(fileName), fileName)),
      error => this.handleError(error);
  }

  base64Image(identifier: string, url: string, fileName: string, loader?: string) {
    this.showLoader(loader);
    const that = this;
    return this.httpClient.get(this.prependApiUrl(url), {
      headers: this.getHttpClientHeadersImage(), withCredentials: true,
    }).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        that.hideLoader();
        return r;
      })
    );

  }

  callerWithoutBody(method: string, identifier: string, url: string): Observable<{}> {
    if (identifier !== 'sign-out' && identifier !== 'meta-data' && identifier !== 'public') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const head = { headers: this.getHttpClientHeaders(), withCredentials: true, };
    const that = this;
    if (method === 'get') {
      return this.httpClient.get(this.prependApiUrl(url), head).pipe(
        catchError((error) => this.handleError(error))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'delete') {
      return this.httpClient.delete(this.prependApiUrl(url), head).pipe(
        catchError((error) => this.handleError(error))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }

  callerWithBody(method: string, identifier: string, url: string, body?: any, isSignin = false): Observable<{}> {
    if (identifier !== 'sign-in' && identifier !== 'sign-out' && identifier !== 'meta-data' && identifier !== 'public') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const that = this;
    const head: any = { headers: this.getHttpClientHeaders(), withCredentials: true };
    if (method === 'put') {
      // return this.httpClient.put(this.prependApiUrl(url), body, head).pipe(
      //   catchError((error) => this.handleError(error))
      // ).pipe(
      //   map((r: Response) => {
      //     that.hideLoader();
      //     return r;
      //   })
      // );
      return this.httpClient.put(this.prependApiUrl(url), body, head).pipe(
        catchError((error) => this.handleError(error))
      ).pipe(
        map((r) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'post') {
      // return this.httpClient.post(this.prependApiUrl(url), body, head).pipe(
      //   catchError((error) => {
      //     return this.handleError(error);
      //   })
      // ).pipe(
      //   map((r: Response) => {

      //     that.hideLoader();
      //     return r;
      //   })
      // );
      return this.httpClient.post(this.prependApiUrl(url), body, head).pipe(
        catchError((error) => {
          return this.handleError(error);
        })
      ).pipe(
        map((r) => {

          that.hideLoader();
          return r;
        })
      );
    }
  }

  public getHttpClientHeaders(): HttpHeaders {
    const token = this.getHeaderName();
    return new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE,
      'X-XSRF-TOKEN': token ? token : '',
      'Timezone': moment.tz.guess()
      // 'Timezone': ''
    });
  }

  private getHttpClientHeadersImage(): HttpHeaders {
    const token = this.getHeaderName();
    return new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      'X-XSRF-TOKEN': token ? token : '',
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
      'Timezone': moment.tz.guess()
      // 'Timezone': ''
    });
  }

  private handleError(error: HttpErrorResponse | any) {
    this.hideLoader();
    if (error.status === 400) {
      return throwError(error.error);
    } else if (error.status === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Server Unreachable.',
        detail: 'Sorry, seems like server is unreachable.'
      });
      return throwError(error.error);
    } else if (error.status === 500) {
      return throwError(error.error);
    } else if (error.status === 401 || error.status === 403) {
      const that = this;
      if (!localStorage.getItem('userId')) {
        that.clearCookies();
      }
      const userId = parseInt(localStorage.getItem('userId').replace(/"/g, ''), 10);
      const idleLogOutTime = this.storageService.getItem('idleTime');
      const pastDate = +(this.storageService.getItemLocalStorage('idleSessionStartTime'));
      const sessionTime = idleLogOutTime ? idleLogOutTime : AppSettings.SESSION_TIMEOUT_IN_MINS
      const session_token = this.storageService.getItemFromCookies('sessionToken');
      const body = {
        "tenantId": AppSettings.TENANT,
        "type": "USER_LOGOUT_LOGS",
        "description": JSON.stringify(error) + ', sessionTime:' + sessionTime + ', pastDate: ' + pastDate + ', currentTime: ' + Date.now() + ', sessionToken: ' + session_token,
        "userId": JSON.stringify(userId),
      }
      return this.httpClient.post(this.prependApiUrl('/record/logs'), body,).pipe(
        catchError((error) => {
          that.clearCookies();
          return this.handleError(error);
        })
      ).pipe(
        map((r) => {
          that.hideLoader();
          that.clearCookies();
          return r;
        })
      );

    }
  }

  downloadFile(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data], { type: contentType });
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    this.hideLoader();
    setTimeout(() => {
      link.remove();
    }, 1000);
  }

  getHeaderName() {
    const cookies = this.storageService.getItemFromCookies('XSRF-TOKEN');
    return cookies;
  }

  getCookie(cookies) {
    const cookie = {};
    cookies.split(';').forEach(function (el) {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });
    return cookie['XSRF-TOKEN'];
  }

  private getContentType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    switch (extension) {
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'bmp':
        return 'image/x-ms-bmp';
      case 'pdf':
        return 'application/pdf';
      case 'xls':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return '';
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(loader?: string): void {
    if (loader !== undefined && loader !== null && 'none' !== loader.toLowerCase()) {
      this.loaderService.show(loader);
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

  private getTimeZoneOffset() {
    return new Date().getTimezoneOffset().toString();
  }

  private handleHttpSuccess(res: Observable<{}>): Observable<{}> {
    return res;
  }

  checkIdleTime() {
    // const idleLogOutTime = this.storageService.getItem('idleTime');
    let idleLogOutTime = this.storageService.getItem('idleTime');
    if (idleLogOutTime === null) {
      idleLogOutTime = AppSettings.IDLE_TIME;
    } else {
      idleLogOutTime = this.storageService.getItem('idleTime');
    }
    const idleTime = '' + moment().add((idleLogOutTime), 'm').valueOf();
    this.storageService.setLocalStorage(AppSettings.INTERVAL, idleTime);
  }

  clearCookies() {
    this.storageService.removeAllCookies();
    this.storageService.removeAll();
    this.router.navigate(['/signin-sso']);
  }

}
